.marginBottom {
  margin-bottom: 80px;
}

.marginTop {
  margin-top: 80px !important;
}

.iconRow {
  display: flex;
}

.myIconStyle {
  font-size: 80px;
  color: rgba(0,0,0,0.6);
}
