@import "~react-image-gallery/styles/css/image-gallery.css";

h2 {
  font-family: 'Oswald', sans-serif;
  font-size: 60px;
  letter-spacing: 3px;
  font-weight: 200;
  color: #4c4c4c;
}

p {
  font-size: 20px;
  line-height: 1.7;
  font-family: 'PT Sans', sans-serif;
  color: #4c4c4c;
}

h3 {
  font-size: 30px;
  font-family: 'PT Sans', sans-serif;
  font-weight: 100;
}

.subHeading {
  font-size: 14px;
  line-height: 1.5;
  font-family: 'PT Sans', sans-serif;
  color: #4c4c4c;
  text-transform: uppercase;
}

.marginTop {
  margin-top: 80px !important;
}

.marginBottom {
  margin-bottom: 120px;
}

.backToProjects {
  font-size: 24px;
  font-family: 'Oswald', sans-serif;
  width: 100%;
  display: flex;
  text-align: center !important;
}

.backToProjects a {
  color: #a1b5bc !important;
  transition: all 0.3s;
}

.backToProjects a:hover {
  opacity: 0.5;
}
