a {
  color: #000;
}

a:hover {
  color: #35403E;
}

h4 {
  font-size: 18px;
  font-weight: 100;
}
