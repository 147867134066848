.my-header {
  background: #a1b5bc !important;
  height: auto;
  /* padding: 20px 0px; */
  border: none !important;
}

.my-logo {
  margin: 2% 2%;
  /* padding: 0 0 5% 0; */
  font-size: 18px;
  color: #fff !important;
}

.my-logo:hover {
  opacity: 0.5;
  transition: all 0.3s;
}

.my-menu a {
  color: #fff !important;
  padding-left: 5%;
}
