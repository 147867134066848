.tableHeading {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  font-family: 'Oswald', sans-serif;
}

table {
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: grey;
  font-family: 'Oswald', sans-serif;
  margin-bottom:80px;
}

.cellstyle {
  border-right: 1px solid #cccc;
  border-bottom: 1px solid #f4f4f4;
  padding: 15px 10px;
}

.headerRow {
  border: 1px solid #ccc;
  font-size: 20px;
  font-family: 'Oswald', sans-serif;
  background: #e1e1e1;
}

.row {
  font-size: 20px;
  font-weight: 100;
}
