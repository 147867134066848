
#nameContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.mainContainer {
  display: flex !important;
  /* width: 85% !important; */
  height: 100vh !important;
  padding-left: 5%;
  align-items: center;
  background: linear-gradient(to right, #c9d7e1 0%,#a1b5bc 100%);
}

h1 {
  font-size: 50px;
  font-family: 'Oswald', sans-serif;
  color: #fff;
  margin-bottom: -5px;
}

.subheading {
  font-size: 22px;
  font-family: 'Oswald', sans-serif;
  font-weight: 100;
  color: #fff;
  letter-spacing: 10px;
  margin: 0 0 80px 0;
}

h3 a{
  font-size: 16px;
  font-family: 'PT Sans', sans-serif;
  font-weight: 100;
  color: #fff !important;
  letter-spacing: 5px;
}

h3 a:hover {
  opacity: 50%;
  transition: all 0.4s;
}


.mainContainer .bird {
	background-image: url('http://brandonwong.com.au/img/bird.svg');
	background-size: auto 100%;
	width: 88px;
	height: 125px;
	will-change: background-position;

	animation-name: fly-cycle;
	animation-duration: 1s;
	animation-delay: -0.5s;
	animation-timing-function: steps(10);
	animation-iteration-count: infinite;
}

.mainContainer .bird-container--one {
	position: absolute;
	top: 20%;
	/* left: 50%; */
	transform: translate(-50%,-50%);
	will-change: transform;

  animation-name: fly-path;
	animation-duration: 13s;
	animation-delay: 0;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

.mainContainer .bird-container--two {
	position: absolute;
	top: 20%;
	/* left: 50%; */
	transform: translate(-50%,-50%);
	will-change: transform;

  animation-name: fly-path-two;
	animation-duration: 15s;
	animation-delay: 0;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

@keyframes fly-cycle {
	100% {
		background-position: -900px 0;
	}
}

@keyframes fly-path{
	0% {
		transform: translateX(-10vw) scale(0.3);
	}
	10% {
		transform: translateY(0vh) translateX(10vw) scale(0.4);
	}
	20% {
		transform: translateY(1vh) translateX(30vw) scale(0.3);
	}
	30% {
		transform: translateY(0vh) translateX(50vw) scale(0.4);
	}
	40% {
		transform: translateY(1vh) translateX(70vw) scale(0.4);
	}
	50% {
		transform: translateY(1vh) translateX(90vw) scale(0.4);
	}
	60% {
		transform: translateY(0vh) translateX(110vw) scale(0.3);
	}
	100% {
		transform: translateY(1vh) translateX(130vw) scale(0.4);
	}
}

@keyframes fly-path-two{
	0% {
		transform: translateX(-10vw) scale(0.3);
	}
	10% {
		transform: translateY(-2vh) translateX(10vw) scale(0.3);
	}
	20% {
		transform: translateY(1vh) translateX(30vw) scale(0.3);
	}
	30% {
		transform: translateY(-1vh) translateX(50vw) scale(0.4);
	}
	40% {
		transform: translateY(1vh) translateX(70vw) scale(0.4);
	}
	50% {
		transform: translateY(0vh) translateX(90vw) scale(0.4);
	}
	51% {
		transform: translateY(0vh) translateX(110vw) scale(0.45);
	}
	100% {
		transform: translateY(1vh) translateX(130vw) scale(0.4);
	}
}
